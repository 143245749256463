import PageMain from "@/components/pageMain/PageMain.vue";
import Area from "./components/area.vue";
import { uploadUrl } from "@/utils/constant.js";
import {
    getSolution,//获取解决方案
    addSolution,//新增解决方案
    editSolution,//修改解决方案
    deleteSolution,//删除解决方案
    getAreaInfoById,//根据解决方案获取分区
    deleteSolutionArea,//删除分区
} from "@/api/dataSet/solution";
export default {
    components: {
        PageMain,
        Area
    },
    data() {
        return {
            solutionData: [],
            solutionDataLoading: false,
            areaData: [],
            areaDataLoading: false,
            dialogSolution: {
                title: '',
                visiable: false,
            },
            solutionForm: {
                solutionName: '',
                solutionDescription: '',
                solutionPcImg: '',
                solutionPhoneImg: '',
                remark: '',
                createBy:'',
                language:this.$route.query.lang*1
            },
            solutionRules: {
                solutionName: [
                    {
                        required: true,
                        message: "请输入解决方案名称",
                        trigger: "blur",
                    },
                ],
            },
            uploadHeaders: {},
            uploadUrl,
            areaVisiable: false,//区域弹窗
            solutionObj: {},//当前解决方案
            areaForm: {},//区域信息
        };
    },
    watch: {
        "$route.query.lang": {
            handler: function (val, oldVal) {
                this.getSolution();
            },
        }
    },
    created() {
        this.uploadHeaders = { Authorization: this.$store.state.user.token };
        this.getSolution();
    },
    methods: {
        // 获取解决方案
        async getSolution() {
            this.solutionDataLoading = true;
            let res = await getSolution(this.$route.query.lang*1);
            if (res.code === 200) {
                this.solutionData = res.data;
                this.solutionObj = res.data.length ? res.data[0] : {
                }
                this.getAreaInfoById(this.solutionObj.solutionId);
                this.solutionDataLoading = false;
            } else {
                this.solutionDataLoading = false;
            }
        },

        // 根绝解决方案查询区域
        async handleNodeClick(row) {
            this.solutionObj = row;
            this.getAreaInfoById(row.solutionId)
        },

        async getAreaInfoById(solutionId) {
            let res = await getAreaInfoById(solutionId);
            if (res.code === 200) {
                this.areaData = res.data.webControlSolutionAreaList;
                this.areaDataLoading = false;
            } else {
                this.areaDataLoading = false;
            }
        },

        // 上传成功
        async uploadSuccess(res, file, type) {
            let fileSize = (file.size / 1024 / 1024);
            if (fileSize > 50) {
                this.$message.error('文件大小超过50MB,请重新上传！');
                return;
            }
            if (res.code !== 200) {
                this.$message.error('上传失败，请稍后再试');
                return;
            }
            try {
                if (type === 'solutionPc')
                    this.solutionForm.solutionPcImg = res.data.url;
                else
                    this.solutionForm.solutionPhoneImg = res.data.url;
                this.$message.success('上传成功');
            } catch (e) {
                this.$message.error('上传失败，请稍后再试');
            }
            this.$refs.avatarUpload.clearFiles();
        },

        //编辑解决方案
        editSolution(type, row) {
            this.dialogSolution = {
                title: type === 'add' ? '新增解决方案' : '编辑【' + row.solutionName + '】',
                visiable: true,
            }
            if (type === "edit") {
                let solutionForm = Object.assign({}, row);
                this.solutionForm = solutionForm;
            } else {
                this.$data.solutionForm = this.$options.data.call(this).solutionForm;
                this.solutionForm.createBy = this.$store.state.user.name;
            }
        },

        editArae(type, row) {
            if (!this.solutionObj.solutionId) {
                this.$message.error('请点击一条解决方案进行新增!');
                return;
            }
            if (type === "edit") {
                let areaForm = Object.assign({}, row);
                this.areaForm = areaForm;
            } else {
                this.$data.areaForm = this.$options.data.call(this).areaForm;
            }
            this.areaVisiable = true;
        },

        // 提交解决方案
        submitFrom(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res = {};
                    if (this.solutionForm.solutionId)
                        res = await editSolution(this.solutionForm);//修改
                    else
                        res = await addSolution(this.solutionForm);
                    if (res.code === 200) {
                        this.$message.success('保存成功');
                        this.dialogSolution.visiable = false;
                        this.getSolution();
                        return;
                    } else {
                        this.$message.error('保存失败');
                    }
                }
            });
        },

        // 删除解决方案
        async deleteSolution(solutionId) {
            let res = await deleteSolution(solutionId);
            if (res.code === 200) {
                this.$message.success('删除成功');
                this.getSolution();
            } else {
                this.$message.error('删除失败');
                return;
            }
        },

        // 删除区域
        async deleteArea(areaId) {
            let res = await deleteSolutionArea(areaId);
            if (res.code === 200) {
                this.$message.success('删除成功');
                this.getAreaInfoById(this.solutionObj.solutionId);
            } else {
                this.$message.error('删除失败');
                return;
            }
        },

        closeArea() {
            this.areaVisiable = false;
            this.getAreaInfoById(this.solutionObj.solutionId);
        }
    },
};