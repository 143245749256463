<template>
  <div>
    <el-row :gutter="10" class="material-wrapper">
      <el-col :span="10">
        <page-main>
          <div class="main-body-header" slot="header">
            <span class="main-title">解决方案</span>
            <div>
              <el-button
                size="mini"
                type="primary"
                @click="editSolution('add', null)"
                >新增解决方案</el-button
              >
            </div>
          </div>
          <div class="table-box" slot="body">
            <el-table
              class="table-wrape"
              :data="solutionData"
              :highlight-current-row="true"
              v-loading="solutionDataLoading"
              height="550px"
              style="font-size: 14px"
              @row-click="handleNodeClick"
            >
              <el-table-column
                label="名称"
                prop="solutionName"
                width="250"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                prop="solutionDescription"
                label="描述"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column prop="remark" label="备注"> </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="editSolution('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-popconfirm
                    title="确定删除该解决吗？"
                    @confirm="deleteSolution(scope.row.solutionId)"
                  >
                    <el-button
                      slot="reference"
                      size="mini"
                      type="text"
                      style="margin-left: 10px"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </page-main>
      </el-col>
      <el-col :span="14">
        <page-main>
          <div class="main-body-header" slot="header">
            <div class="main-title">{{solutionObj.solutionName}}区域</div>
            <div>
              <el-button size="mini" type="primary"  @click="editArae('add',null)">新增区域</el-button>
            </div>
          </div>
          <div class="table-box" slot="body">
            <el-table
              class="table-wrape"
              :data="areaData"
              v-loading="areaDataLoading"
              style="font-size: 14px"
              height="550px"
            >
              <el-table-column
                label="区域名"
                prop="areaName"
                width="250"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column prop="remark" label="备注"   :show-overflow-tooltip="true"> </el-table-column>
              <el-table-column  label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="editArae('edit',scope.row)"
                    >编辑</el-button
                  >
                  <el-popconfirm
                    title="确定删除该区域吗？"
                    @confirm="deleteArea(scope.row.areaId)"
                  >
                    <el-button
                      slot="reference"
                      size="mini"
                      type="text"
                      style="margin-left: 10px"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </page-main>
      </el-col>
    </el-row>

    <!-- 解决方案新增 -->
    <el-dialog
      :title="dialogSolution.titile"
      :visible.sync="dialogSolution.visiable"
      width="50%"
      top="5vh"
    >
      <el-form
        ref="solutionForm"
        :model="solutionForm"
        :rules="solutionRules"
        label-width="100px"
      >
        <el-form-item label="名称" prop="solutionName">
          <el-input
            class="form-table_inpse"
            v-model="solutionForm.solutionName"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6}"
            class="form-table_inpse"
            v-model="solutionForm.solutionDescription"
          ></el-input>
        </el-form-item>
        <el-form-item label="PC端元素">
          <el-row :gutter="10">
            <el-col :span="10">
              <div>
                <img
                  width="220"
                  height="170"
                  v-if="solutionForm.solutionPcImg"
                  :src="solutionForm.solutionPcImg"
                />
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                ref="avatarUpload"
                class="upload-demo"
                :action="uploadUrl"
                accept="image/jpeg,image/png"
                :on-success="
                      (res, file) => {
                        uploadSuccess(res, file, 'solutionPc');
                      }
                    "
                :headers="uploadHeaders"
              >
              <el-button size="mini" type="primary"
                              >点击上传</el-button
                            >
                <div class="el-upload__tip" slot="tip">
                  只能上传jpg/png文件,大小不超过50MB
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="手机端元素">
          <el-row :gutter="10">
            <el-col :span="10">
              <div>
                <img
                  width="220"
                  height="170"
                  v-if="solutionForm.solutionPhoneImg"
                  :src="solutionForm.solutionPhoneImg"
                />
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                ref="avatarUpload"
                class="upload-demo"
                accept="image/jpeg,image/png"
                :action="uploadUrl"
                :on-success="
                      (res, file) => {
                        uploadSuccess(res, file, 'solutionPhone');
                      }
                    "
                :headers="uploadHeaders"
              >
              <el-button size="mini" type="primary"
                              >点击上传</el-button
                            >
                <div class="el-upload__tip" slot="tip">
                  只能上传jpg/png文件,大小不超过50MB
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="14">
            <el-form-item label="备注">
              <el-input
                class="form-table_inpse"
                v-model="solutionForm.remark"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="创建人">
              <el-input
                class="form-table_inpse"
                v-model="solutionForm.createBy"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSolution.visiable = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom('solutionForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 区域编辑 -->
    <Area v-if="areaVisiable" :areaVisiable="areaVisiable" :solutionObj="solutionObj" :areaFormPop="areaForm" @closeArea="closeArea"/>
  </div>
</template>
    
<script>
import index from "./index.js";
export default index;
</script>
    
<style scoped lang="scss">
@import "../common.scss";
</style>
    