import PageMain from "@/components/pageMain/PageMain.vue";
import { uploadUrl } from "@/utils/constant.js";
import {
    addSolutionArea,//新增解决方案区域
    editSolutionArea,//修改解决方案区域
    getAreaDetail,//获取解决方案区域详细信息
} from "@/api/dataSet/solution";
export default {
    props: {
        areaVisiable: {
            type: Boolean,
            default: false,
        },
        areaFormPop: {
            type: Object,
            default: {},
        },
        solutionObj: {
            type: Object,
            default: {},
        },
    },
    components: {
        PageMain,
    },
    data() {
        return {
            equipmentList: [],
            painList: [],
            solutionCaseList: [],
            areaForm:this.areaFormPop,
            areaFormRules: {
                areaName: [
                    {
                        required: true,
                        message: "请输入解决方案名称",
                        trigger: "blur",
                    },
                ],
            },
            uploadHeaders: {},
            uploadUrl,
            itemName: '核心设备',//也卡名
        };
    },
    watch: {
        'areaForm.areaId': {
            handler(areaId) {
                if (areaId) {
                    //根据区域查询详情
                    this.getAreaDetail(areaId);
                } else {
                    this.areaForm = {
                        solutionId: null,
                        areaName: '',
                        areaPcImg: '',
                        areaPhoneImg: '',
                        remark: '',
                        sort:null,
                        webControlSolutionEquipmentList: [],
                        webControlSolutionPainList: [],
                        webControlSolutionCaseList: [],
                        createBy: this.$store.state.user.name,
                    }
                }
            },
            immediate: true,
        },
    },
    created() {
        this.uploadHeaders = { Authorization: this.$store.state.user.token };
    },
    methods: {
        async getAreaDetail(areaId) {
            let res = await getAreaDetail(areaId);
            if (res.code == 200) {
                this.areaForm = res.data;
                this.equipmentList=  res.data.webControlSolutionEquipmentList;
                this.painList= res.data.webControlSolutionPainList;
                this.solutionCaseList= res.data.webControlSolutionCaseList;
            }
        },
        addRow() {
            switch (this.itemName) {
                case '核心设备':
                    this.equipmentList.push({
                        equipmentName: '',
                        equipmentPcImg: '',
                        equipmentPhoneImg: '',
                        remark: '',
                        sort:null
                    })
                    break;
                case '痛点问题':
                    this.painList.push({
                        painName: '',
                        painDescription: '',
                        painPcImg: '',
                        remark: '',
                        sort:null
                    })
                    break;
                case '解决方案':
                    this.solutionCaseList.push({
                        solutionCaseName: '',
                        solutionCasePcImg: '',
                        solutionCaseImg: '',
                        remark: '',
                        sort:null
                    })
                    break;
                default:
                    break;
            }
        },

        // 上传成功
        async uploadSuccess(res, file, type, row) {
            let fileSize = (file.size / 1024 / 1024);
            if (fileSize > 15) {
                this.$message.error('文件大小超过15MB,请重新上传！');
                return;
            }
            if (res.code !== 200) {
                this.$message.error('上传失败，请稍后再试');
                return;
            }
            try {
                switch (type) {
                    // 区域
                    case 'areaPc':
                        this.areaForm.areaPcImg = res.data.url;
                        break;
                    case 'areaPhone':
                        this.areaForm.areaPhoneImg = res.data.url;
                        break;
                    // 设备上传
                    case 'equipmentPc':
                        row.equipmentPcImg = res.data.url;
                        break;
                    case 'equipmentPhone':
                        row.equipmentPhoneImg = res.data.url;
                        break;
                    // 痛点问题
                    case 'painPc':
                        row.painPcImg = res.data.url;
                        break;
                    case 'painPhone':
                        row.painPhoneImg = res.data.url;
                        break;
                    // 解决方案
                    case 'solutionCasePc':
                        row.solutionCasePcImg = res.data.url;
                        break;
                    case 'solutionCasePhone':
                        row.solutionCasePhoneImg = res.data.url;
                        break;
                    default: break;
                }

                this.$message.success('上传成功');
            } catch (e) {
                this.$message.error('上传失败，请稍后再试');
            }
            this.$refs.avatarUpload.clearFiles();
        },

        // 提交区域编辑
        submitFrom(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res = {};
                    this.areaForm.solutionId = this.solutionObj.solutionId;
                    this.areaForm.webControlSolutionEquipmentList = this.equipmentList;
                    this.areaForm.webControlSolutionPainList = this.painList;
                    this.areaForm.webControlSolutionCaseList = this.solutionCaseList;
                    if (this.areaForm.areaId) {
                        res = await editSolutionArea(this.areaForm);
                    } else {
                        res = await addSolutionArea(this.areaForm);
                    }
                    if (res.code === 200) {
                        this.$message.success('保存成功');
                        this.closeArea();
                        return;
                    } else {
                        this.$message.error('保存失败');
                    }
                }
            });
        },

        // 移除行数据
        deleteRow(row, type,index) {
            if (type === 'equipment') {
                // 删除设备
                this.equipmentList.splice(index, 1);
            }
            else if (type === 'pain') {
                // 痛点问题
                this.painList.splice(index, 1);
            } else {
                // 解决方案
                this.solutionCaseList.splice(index, 1);
            }
        },

        closeArea() {
            this.$emit('closeArea')
        }
    },
};