<template>
  <el-dialog
    title="编辑区域信息"
    :visible.sync="areaVisiable"
    :close-on-click-modal="false"
    @close="closeArea"
    width="80%"
    top="5vh"
  >
    <div style="height: 500px; overflow-y: auto; overflow-x: hidden">
      <page-main>
        <div class="main-body-header" slot="header">
          <span class="main-title">
            {{ areaForm.areaName ? areaForm.areaName : "新增区域" }}
            <span style="font-size: 10px">
              文件只能上传jpg/png文件,大小不超过15MB<i
                class="el-icon-warning"
              ></i
            ></span>
          </span>
        </div>
        <div class="table-box" slot="body">
          <el-form
            ref="areaForm"
            :model="areaForm"
            :rules="areaFormRules"
            label-width="100px"
          >
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item label="序号" prop="sort">
                  <el-input
                    type="number"
                    size="small"
                    class="form-table_inpse"
                    v-model="areaForm.sort"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="9">
                <el-form-item label="名称" prop="areaName">
                  <el-input
                  type="textarea"
                    size="small"
                    class="form-table_inpse"
                    v-model="areaForm.areaName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="描述" prop="remark">
                  <el-input
                    type="textarea"
                    style="width: 90%"
                    size="small"
                    class="form-table_inpse"
                    v-model="areaForm.remark"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="PC端元素">
                  <div class="upload-item">
                    <el-image
                      style="width: 120px; height: 100px"
                      v-if="areaForm.areaPcImg"
                      :src="areaForm.areaPcImg"
                      :preview-src-list="[areaForm.areaPcImg]"
                    />
                    <el-upload
                      ref="avatarUpload"
                      class="upload-demo"
                      accept="image/jpeg,image/png"
                      :action="uploadUrl"
                      :on-success="
                        (res, file) => {
                          uploadSuccess(res, file, 'areaPc');
                        }
                      "
                      :headers="uploadHeaders"
                    >
                      <el-button size="mini" type="primary">上传</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机端元素">
                  <div class="upload-item">
                    <el-image
                      style="width: 120px; height: 100px"
                      v-if="areaForm.areaPhoneImg"
                      :src="areaForm.areaPhoneImg"
                      :preview-src-list="[areaForm.areaPhoneImg]"
                    />

                    <el-upload
                      ref="avatarUpload"
                      class="upload-demo"
                      accept="image/jpeg,image/png"
                      :action="uploadUrl"
                      :on-success="
                        (res, file) => {
                          uploadSuccess(res, file, 'areaPhone');
                        }
                      "
                      :headers="uploadHeaders"
                    >
                      <el-button size="mini" type="primary">上传</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <page-main>
              <div class="main-body-header" slot="header">
                <span class="main-title">
                  区域信息
                  <span style="font-size: 10px">
                    文件只能上传jpg/png文件,大小不超过15MB<i
                      class="el-icon-warning"
                    ></i></span
                ></span>
                <div>
                  <el-button size="mini" type="primary" @click="addRow"
                    >新增{{ itemName }}</el-button
                  >
                </div>
              </div>
              <div class="table-box" slot="body">
                <el-tabs type="border-card" v-model="itemName">
                  <el-tab-pane label="核心设备" name="核心设备">
                    <el-table
                      class="set-table"
                      :data="equipmentList"
                      style="font-size: 14px"
                      height="320px"
                      :header-cell-style="{
                        color: '#333333',
                        fontSize: '13px',
                      }"
                    >
                      <el-table-column label="序号" prop="sort" width="100">
                        <template slot-scope="scope">
                          <el-input
                            type="number"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.sort"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="设备名"
                        prop="name"
                        width="250"
                        :show-overflow-tooltip="true"
                      >
                        <template slot-scope="scope">
                          <el-input
                          type="textarea"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.equipmentName"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="PC端图片">
                        <template slot-scope="scope">
                          <div class="upload-item">
                            <el-image
                              style="width: 60px; height: 60px"
                              v-if="scope.row.equipmentPcImg"
                              :src="scope.row.equipmentPcImg"
                              :preview-src-list="[scope.row.equipmentPcImg]"
                            />
                            <el-upload
                              ref="avatarUpload"
                              class="upload-demo"
                              accept="image/jpeg,image/png"
                              :action="uploadUrl"
                              :on-success="
                                (res, file) => {
                                  uploadSuccess(
                                    res,
                                    file,
                                    'equipmentPc',
                                    scope.row
                                  );
                                }
                              "
                              :headers="uploadHeaders"
                            >
                              <el-button size="mini" type="primary"
                                >上传</el-button
                              >
                            </el-upload>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="手机端图片">
                        <template slot-scope="scope">
                          <div class="upload-item">
                            <el-image
                              style="width: 60px; height: 60px"
                              v-if="scope.row.equipmentPhoneImg"
                              :src="scope.row.equipmentPhoneImg"
                              :preview-src-list="[scope.row.equipmentPhoneImg]"
                            />

                            <el-upload
                              ref="avatarUpload"
                              class="upload-demo"
                              accept="image/jpeg,image/png"
                              :action="uploadUrl"
                              :on-success="
                                (res, file) => {
                                  uploadSuccess(
                                    res,
                                    file,
                                    'equipmentPhone',
                                    scope.row
                                  );
                                }
                              "
                              :headers="uploadHeaders"
                            >
                              <el-button size="mini" type="primary"
                                >上传</el-button
                              >
                            </el-upload>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="备注">
                        <template slot-scope="scope">
                          <el-input
                            type="textarea"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.remark"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="操作" width="80">
                        <template slot-scope="scope">
                          <el-popconfirm
                            title="确定删除该核心吗？"
                            @confirm="
                              deleteRow(scope.row, 'equipment', scope.$index)
                            "
                          >
                            <el-button
                              slot="reference"
                              size="mini"
                              type="text"
                              style="margin-left: 10px"
                              >移除</el-button
                            >
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="痛点问题" name="痛点问题">
                    <el-table
                      class="set-table"
                      :data="painList"
                      style="font-size: 14px"
                      height="320px"
                      :header-cell-style="{
                        color: '#333333',
                        fontSize: '13px',
                      }"
                    >
                      <el-table-column label="序号" prop="sort" width="100">
                        <template slot-scope="scope">
                          <el-input
                            type="number"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.sort"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="名称"
                        prop="painName"
                        width="250"
                        :show-overflow-tooltip="true"
                      >
                        <template slot-scope="scope">
                          <el-input
                          type="textarea"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.painName"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="描述">
                        <template slot-scope="scope">
                          <el-input
                          type="textarea"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.painDescription"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="PC端图片">
                        <template slot-scope="scope">
                          <div class="upload-item">
                            <el-image
                              style="width: 60px; height: 60px"
                              v-if="scope.row.painPcImg"
                              :src="scope.row.painPcImg"
                              :preview-src-list="[scope.row.painPcImg]"
                            />

                            <el-upload
                              ref="avatarUpload"
                              class="upload-demo"
                              accept="image/jpeg,image/png"
                              :action="uploadUrl"
                              :on-success="
                                (res, file) => {
                                  uploadSuccess(res, file, 'painPc', scope.row);
                                }
                              "
                              :headers="uploadHeaders"
                            >
                              <el-button size="mini" type="primary"
                                >上传</el-button
                              >
                            </el-upload>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="手机端图片">
                        <template slot-scope="scope">
                          <div class="upload-item">
                            <el-image
                              style="width: 60px; height: 60px"
                              v-if="scope.row.painPhoneImg"
                              :src="scope.row.painPhoneImg"
                              :preview-src-list="[scope.row.painPhoneImg]"
                            />

                            <el-upload
                              ref="avatarUpload"
                              class="upload-demo"
                              accept="image/jpeg,image/png"
                              :action="uploadUrl"
                              :on-success="
                                (res, file) => {
                                  uploadSuccess(
                                    res,
                                    file,
                                    'painPhone',
                                    scope.row
                                  );
                                }
                              "
                              :headers="uploadHeaders"
                            >
                              <el-button size="mini" type="primary"
                                >上传</el-button
                              >
                            </el-upload>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="操作" width="80">
                        <template slot-scope="scope">
                          <el-popconfirm
                            title="确定删除该痛点问题吗？"
                            @confirm="
                              deleteRow(scope.row, 'pain', scope.$index)
                            "
                          >
                            <el-button
                              slot="reference"
                              size="mini"
                              type="text"
                              style="margin-left: 10px"
                              >移除</el-button
                            >
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="解决方案" name="解决方案">
                    <el-table
                      class="set-table"
                      :data="solutionCaseList"
                      style="font-size: 14px"
                      height="320px"
                      :header-cell-style="{
                        color: '#333333',
                        fontSize: '13px',
                      }"
                    >
                      <el-table-column label="序号" prop="sort" width="100">
                        <template slot-scope="scope">
                          <el-input
                            type="number"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.sort"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="名称"
                        prop="solutionCaseName"
                        width="250"
                        :show-overflow-tooltip="true"
                      >
                        <template slot-scope="scope">
                          <el-input
                          type="textarea"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.solutionCaseName"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="PC端图片">
                        <template slot-scope="scope">
                          <div class="upload-item">
                            <el-image
                              style="width: 60px; height: 60px"
                              v-if="scope.row.solutionCasePcImg"
                              :src="scope.row.solutionCasePcImg"
                              :preview-src-list="[scope.row.solutionCasePcImg]"
                            />

                            <el-upload
                              ref="avatarUpload"
                              class="upload-demo"
                              accept="image/jpeg,image/png"
                              :action="uploadUrl"
                              :on-success="
                                (res, file) => {
                                  uploadSuccess(
                                    res,
                                    file,
                                    'solutionCasePc',
                                    scope.row
                                  );
                                }
                              "
                              :headers="uploadHeaders"
                            >
                              <el-button size="mini" type="primary"
                                >上传</el-button
                              >
                            </el-upload>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="手机端图片">
                        <template slot-scope="scope">
                          <div class="upload-item">
                            <el-image
                              style="width: 60px; height: 60px"
                              v-if="scope.row.solutionCasePhoneImg"
                              :src="scope.row.solutionCasePhoneImg"
                              :preview-src-list="[
                                scope.row.solutionCasePhoneImg,
                              ]"
                            />

                            <el-upload
                              ref="avatarUpload"
                              class="upload-demo"
                              accept="image/jpeg,image/png"
                              :action="uploadUrl"
                              :on-success="
                                (res, file) => {
                                  uploadSuccess(
                                    res,
                                    file,
                                    'solutionCasePhone',
                                    scope.row
                                  );
                                }
                              "
                              :headers="uploadHeaders"
                            >
                              <el-button size="mini" type="primary"
                                >上传</el-button
                              >
                            </el-upload>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="备注">
                        <template slot-scope="scope">
                          <el-input
                          type="textarea"
                            size="small"
                            class="form-table_inpse"
                            v-model="scope.row.remark"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="操作" width="80">
                        <template slot-scope="scope">
                          <el-popconfirm
                            title="确定删除该解决方案吗？"
                            @confirm="
                              deleteRow(scope.row, 'solution', scope.$index)
                            "
                          >
                            <el-button
                              slot="reference"
                              size="mini"
                              type="text"
                              style="margin-left: 10px"
                              >移除</el-button
                            >
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </page-main>
          </el-form>
        </div>
      </page-main>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeArea">取消</el-button>
      <el-button type="primary" @click="submitFrom('areaForm')">保存</el-button>
    </span>
  </el-dialog>
</template>
      
  <script>
import area from "./area.js";
export default area;
</script>
      
<style scoped lang="scss">
@import "../../common.scss";

/deep/ .page-main-box .box-body {
  padding: 0px 0px !important;
}

.upload-item {
  display: flex;
  align-content: center;
}

// /deep/.el-tabs__content{
//   height: 360px;
// }

.set-table {
  height: 350px;
  overflow: auto;
}

/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 5px !important;
}
</style>
      